<template>

  <section>

    <b-card title="Voucher Table">

      <!-- search input -->
      <div>         
        <b-row>
          <b-col cols="6"> 
            <b-button v-b-modal.modal-edit variant="primary" @click="newVoucher">New Voucher</b-button>
          </b-col>
          <b-col cols="6">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
              </b-form-group>            
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="fields" :rows="vouchers" ref="table"
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :pagination-options="{ enabled: true, perPage:pageLength }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === '_'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editVoucher(props.row.id)"><feather-icon icon="EditIcon" /></a>
          </span>
          <span v-else-if="props.column.field === 'contract'" class="text-nowrap">
            {{ contractMap[props.row.contract].name }}
          </span>
          <!--
          <span v-else-if="props.column.field === 'id'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editVoucher(props.row.id)">{{ props.row.id }}</a>
          </span>
          <span v-else-if="props.column.field === 'type_text'" class="text-nowrap">            
            <a v-b-modal.modal-edit  @click="editVoucher(props.row.id)">{{ (props.row.type_text) }}</a>
          </span>
          <span v-else-if="props.column.field === 'address'" class="text-nowrap">
            <a v-b-modal.modal-edit @click="editVoucher(props.row.id)">{{ props.row.address }}</a>
          </span -->
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['10','20','50','100']" class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">            
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
        </div>
        </template>
      </vue-good-table>

    </b-card>

    <b-modal ref="editmodal"
      id="modal-edit"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"      
      :title="editedVoucher.title"
      @ok="saveVoucher" :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group>
          Type <b-form-select v-model="editedVoucher.type" :options="voucherTypeOptions" />
        </b-form-group>
        <b-form-group>
          Contract <b-form-select v-model="editedVoucher.contract" :options="contracts" />
        </b-form-group>  
        <b-form-group>
          Address <b-form-input v-model="editedVoucher.address" placeholder="Wallet Address"/>                      
        </b-form-group>
        <b-form-group>
          Amount <b-form-input v-model="editedVoucher.amount"  placeholder="Put 0 if the amount will be determine by contract"/>                      
        </b-form-group>
        <template v-if="editedVoucher.type == 'V'">
          <b-form-group>
            Price <b-form-input v-model="editedVoucher.price" placeholder="Put 0 if the voucher is free"/>
          </b-form-group>
          <b-form-group>
            Currency <b-form-select v-model="editedVoucher.currency" :options="currencyOptions" />                      
          </b-form-group>
        </template>
        <b-form-group>
          Comment          
          <b-form-textarea rows="3" v-model="editedVoucher.comment" placeholder="Comments/Remark"/>                      
        </b-form-group>
      </b-form>
    </b-modal>

  </section>

</template>

<script>

import { BIcon, BLink, BCard, BPagination, BForm, BTabs, BTab, BTable, BCardText, BButton, BFormSelect, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BFormTextarea } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
    data: function() {
        return { 
            editedVoucher: {},
            pageLength: 10,
            fields: [
                { key: '_', field: '_', label: ''},
                // { key: 'id', field: 'id', label: 'Id' },
                { key: 'contract' , field: 'contract' , label: 'Contract', sortFn(x,y,rowX,rowY) { return (x < y ? -1 : (x > y ? 1 : 0)); } },
                { key: 'type_name', field: 'type_name', label: 'Type' },
                { key: 'address' , field: 'address', label: 'Address' },
                { key: 'amount'  , field: 'amount' , label: 'Amount', sortFn(x,y,rowX,rowY) { return (x < y ? -1 : (x > y ? 1 : 0)); } },
                { key: 'price'   , field: 'price'  , label: 'Price', sortFn(x,y,rowX,rowY) { return (x < y ? -1 : (x > y ? 1 : 0)); } },
                { key: 'currency', field: 'currency' , label: 'CCY' },                
                { key: 'comment' , field: 'comment', label: 'Comment'},    
                { key: 'publish' , field: 'publish'  , label: 'Publish?', formatter: value => ((value !== null) ? 'Y' : '-')},            
            ],
            searchTerm: '',
            voucherTypeOptions: [             
              { value: '0', text: 'Void'        },
              { value: 'W', text: 'Whitelisted' },
              { value: 'V', text: 'Voucher'     },              
            ],
            currencyOptions: [
              { value: 'ETH', text: 'ETH' },
              { value: 'USD', text: 'USD' },
            ]
        }
    },
    computed: {
        ...mapGetters("JCCDVoucher",["vouchers", "isVoucherReady", "voucherMap"]),   
        ...mapGetters("JCCDContract",["contracts", "isContractReady", "contractMap"]),   
    },
    methods: {
        ...mapActions("JCCDVoucher",["loadVouchers", "updateVoucher", "createVoucher"]),
        ...mapActions("JCCDContract",["loadContracts"]),
        ...mapActions("logs",["logToast"]),
        async editVoucher(id) {
            this.editedVoucher = { ...this.voucherMap[id], mode : 'edit', title: 'Edit Voucher' }; 
        },
        async newVoucher() {          
            this.editedVoucher = {
                mode : 'new',
                type : 'W',
                contract: this.contracts[0].id,
                currency: 'ETH',
                address: '',
                amount: '',
                price: '',
                comment: '',
                title: 'New Voucher'
            }            
        },
        async saveVoucher(bvModalEvt) {            
            bvModalEvt.preventDefault();            
            if (this.editedVoucher.address == "") {
                bvModalEvt.preventDefault();
                this.showToast("error","Address is Empty");
                return;
            }
            if (this.editedVoucher.amount == "") {
                this.editedVoucher.amount = '0'
            }
            if (this.editedVoucher.type == 'W') {            
                this.editedVoucher.price    = null
                this.editedVoucher.currency = null
            } 
            if (this.editedVoucher.mode == 'edit') {
                await this.updateVoucher(this.editedVoucher).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create voucher", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create voucher", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            } else {
                await this.createVoucher(this.editedVoucher).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create voucher", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create voucher", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            }
        },
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        },       
    },
    mounted: function() {
      this.loadVouchers()
      if (!this.isContractReady) this.loadContracts()
    },
    components: {
        BCardText,
        BCardActions,
        BCard, BPagination, BIcon,
        BLink, BTable, BCol, BRow, BTabs, BTab, BButton, BFormSelect, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BForm,
        VueGoodTable,
        BInputGroupPrepend,
        ButtonSpinner,
        ToastificationContent,
    },
    directives: {
        Ripple,
    },    
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>